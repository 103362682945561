import React, { Component } from "react"

class Privacy extends Component{

    state={
        user:this.props.loggedInUser,
    }
    
    render(){
        return(
        <div className="content">
            <h1 className="headline-top">Política de Privacidade</h1>
            <h3 className="justified">
            A sua privacidade é importante para nós. Este site é apenas ilustrativo, pelo que não iremos recolher qualquer tipo de informação pessoal. 
            <br/>
            O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respetivas políticas de privacidade.
            <br/>
            Os conteúdos multimédia são propriedade da pastelaria Andremar São Marcos.
            <br/>
            Caso não concorde com a politica de privacidade, deverá terminar de imediato a ligação ao nosso Website. 
            <br/>
            O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais.
            <br/>
            O nosso site cookies de sessão, de modo a recriar uma sessão anónima entre o seu navegador e nosso servidor. Esse cookie será armazenado no seu computador, mas de qualquer modo irá recolher ou armazenar algum tipo de dado pessoal ou outro dado do seu navegador/ computador.
            <br/>
            A pastelaria Andremar reserva o direito de alterar a sua política de privacidade sem aviso prévio, pelo que aconselha o utilizador a revisitar os termos de privacidade regularmente.
            <br/>
            <br/>
            <br/>
            Esta política é efetiva a partir de 22 Maio de 2022.

            </h3>
        </div>
        )
    }
}

export default Privacy