import React, { Component } from "react"

class TermsAndConditions extends Component{

    state={
        user:this.props.loggedInUser,
    }
    
    render(){
        return(
        <div className="content">
            <h1 className="headline-top">Termos e condições</h1>
            <h3 className="justified">
                O utilizador compromete-se a fazer uso adequado dos conteúdos e da informação que o Andremar oferece no site e com carácter enunciativo, mas não limitativo:
                <br/>
                <br/>
                •  Não usar o site de maneira a que transgrida ou infrinja os direitos de qualquer entidade quer seja pessoa individual ou coletiva
                <br/>
                • Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do andremar.pt, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
                <br/>
                • A utilização, cópia, alteração, reprodução, atualização, descarregamento, transmissão, distribuição, ou qualquer outro ato abusivo dos conteúdos multimédia do nosso Website são proibidos.
                <br/>
                <br/>
                  A pastelaria Andremar São Marcos reserva o direito de alterar os seus termos e condições sem aviso prévio, pelo que aconselha o utilizador a revisitar os termos e condições regularmente.
                <br/>
                  A pastelaria Andremar São Marcos tentará assegurar o acesso ao site aos seus utilizadores.
                <br/>
                  Este acesso poderá ser interrompido sem aviso prévio caso o site se encontre em manutenção, recuperação e /ou introdução de novas funcionalidades.
                  <br/>
            <br/>
            <br/>
            Estes termos e codições são efetivos a partir de 22 Maio de 2022.
            </h3>
        </div>
        )
    }
}

export default TermsAndConditions