import React from "react"
import Carousel from 'react-bootstrap/Carousel'

function InitialPage(){

    return(<div className="content">
            <Carousel className="carousel" >
                <Carousel.Item className="carousel-item">
                    <img className="carousel-image img1"
                    src="https://res.cloudinary.com/dxqsjpa34/image/upload/v1609262745/myFolder/IMG_9518.JPG.jpg"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h3>Boost your energy levels</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carousel-item">
                    <img className="carousel-image img2"
                    src="https://res.cloudinary.com/dxqsjpa34/image/upload/v1609263588/myFolder/IMG_9397.JPG.jpg"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Search for a particular coffee</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carousel-item">
                    <img className="carousel-image img3"
                    src="https://res.cloudinary.com/dxqsjpa34/image/upload/v1609262745/myFolder/IMG_9518.JPG.jpg"
                    alt="Third slide"
                    />
                    <Carousel.Caption>
                    <h3>Where you can find the best coffees in the world</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            </div>
    )

}
export default InitialPage