import React, { Component } from "react"
import {Container,CardDeck, Button} from "react-bootstrap"
import axios from "axios"
import Product from './Product'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0,81,115);
`;

class Category extends Component{

    state={
        products:[],
        name:"",
        file:"",
        editName:"",
        editDescription:"",
        user:false,
        submitNewProduct:false,
        idAndName:[],
        loading:true
    }
    componentDidMount(){
        axios.get(`${process.env.REACT_APP_API}/api/category-detail/${this.props.match.params.id}`)
        .then(response =>{
            this.setState({
                name:response.data.name,
                products:response.data.products,
                user:this.props.loggedInUser,
                loading:false
            })
        })
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }
    handleFileChange = (event) => {
        const {name} = event.target;
        this.setState({ [name]: event.target.files[0]});
    }
    sortByName= ()=>{
        
        let copyArray=this.state.idAndName
        copyArray.sort((a,b)=>{
            if(a.name<b.name) return-1
            if(a.name>b.name) return 1
            return 0
        })
        this.setState({
            idAndName:copyArray
        })
    }

    addProduct = () =>{
        if(this.state.file){ 
        const uploadData = new FormData()
        uploadData.append("url", this.state.file);
        axios.post(`${process.env.REACT_APP_API}/api/upload-product`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                const name=this.state.editName;
                const description = this.state.editDescription
                const category = "category"
                const categoryId = this.props.match.params.id
                axios
                .post(`${process.env.REACT_APP_API}/api/add-product`,{name,category,categoryId,description,url})
                .then(product =>{
                    //newToday.push()
                    const copyProducts=this.state.products
                    copyProducts.push(product.data._id)
                    this.setState({
                        products:copyProducts
                    })
                })
            })
        }
        else{
            const url = ""
            const name=this.state.editName;
            const description = this.state.editDescription
            const category = "category"
            const categoryId = this.props.match.params.id
            axios
            .post(`${process.env.REACT_APP_API}/api/add-product`,{name,category,categoryId,description,url})
            .then(product =>{
                const copyProducts=this.state.products
                copyProducts.push(product.data._id)
                this.setState({
                    products:copyProducts
                })
            })
        }
    }
    cancelNewProduct = () =>{
        this.setState({
            submitNewProduct:!this.state.submitNewProduct
        })
    }

    deleteProduct = (id) =>{
        const categoryId = this.props.match.params.id
        axios.delete(`${process.env.REACT_APP_API}/api/delete-product/${id}/${categoryId}`)
        .then((deletedProduct)=>{
            const copyProducts = this.state.products.filter(productId=>{
                return productId!==id
            })
            this.setState({
                products:copyProducts
            })
        })

    }

    liftUpName=(idAndNamePair)=>{
        const copyIdAndName=this.state.idAndName
        copyIdAndName.push(idAndNamePair)
        this.setState({
            idAndName:copyIdAndName
        })
        this.sortByName()
        
        if(this.state.idAndName.length===this.state.products.length){
            const copyProducts=this.state.products
            const mappedProduct = copyProducts.map((product,index) =>{
                product=this.state.idAndName[index].id
                return product
            })
            this.setState({
                products:mappedProduct
            })
        }
    }
    render(){
        return(
            <div className="content" >
                <ClipLoader css={override} size={450} color={"#123abc"} loading={this.state.loading} />
                {this.state.user &&
                this.state.submitNewProduct ? 
                    <form>
                        <label>Nome</label>
                        <input type="text" placeholder={this.state.editName} name="editName" value ={this.state.editName} onChange={this.handleChange}></input>
                        <label>Descrição</label>
                        <input type="text" placeholder={this.state.editDescription} name="editDescription" value ={this.state.editDescription} onChange={this.handleChange}></input>
                        <label>Seleccionar Imagem</label>
                        <input id="upload-file"  name="file" type="file" onChange={this.handleFileChange} />                    
                        <Button variant="crud" onClick={this.addProduct}>Submeter edição</Button>
                        <Button variant="crud" onClick={this.cancelNewProduct}>Cancelar edição</Button>    
                    </form>:
                    this.state.user && <Button variant="crud" onClick={this.cancelNewProduct}>Adicionar novo producto</Button>}
                <Container className="container-fluid mt-4">
                <h1>{this.state.name}</h1>
                    <CardDeck className="center">
                    {this.state.products.map(id =>{
                        return(                        
                            <Product liftUpName={this.liftUpName} deleteProduct = {this.deleteProduct} user={this.state.user} key={id} id={id}/>
                        )
                    })}
                </CardDeck>
                </Container>
            </div>
        )
    }
}
export default Category