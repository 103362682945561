import React, { Component } from "react"
import { Container,Col,CardDeck,Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0,81,115);
`;
    
class ListOfCategories extends Component {

    state={
        addCategoryName:"",
        addNewDescription:"",
        addNewUrl:"",
        addButton:false,
        editCategoryName:"",
        editCategoryDescription:"",
        editButton:false,
        file:"",
        editFile:"",
        categories:[],
        loading:true
    }

    componentDidMount(){
        axios.get(`${process.env.REACT_APP_API}/api/categories`)
        .then(categories =>{
            this.setState({
                categories:categories.data,
                user:this.props.loggedInUser,
                loading:false
            })
            this.sortByName()
        })
    }
    sortByName= ()=>{
        let copyArray=this.state.categories
        copyArray.sort((a,b)=>{
            if(a.name<b.name) return-1
            if(a.name>b.name) return 1
            return 0
        })
        this.setState({
            categories:copyArray
        })
    }
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }
    handleFileChange = (event) => {
        const {name} = event.target;
        this.setState({ [name]: event.target.files[0]});
    }
    clickOnImage = () =>{
        alert("Clicked")
    }
    addNewCategory = (event) =>{

        event.preventDefault()
        if(this.state.file){ 
            const uploadData = new FormData()
            uploadData.append("url", this.state.file);
            axios.post(`${process.env.REACT_APP_API}/api/upload-category`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                const name = this.state.addCategoryName
                const description = this.state.addNewDescription

                axios
                .post(`${process.env.REACT_APP_API}/api/add-category`,{name,description,url})
                .then(category =>{

                        const addedCategories=this.state.categories
                        addedCategories.push(category.data)
                        this.setState({
                            categories:addedCategories                    
                        })
                        this.sortByName()
                })
            })
        }
        else{
            const url = ""
            const name = this.state.addCategoryName
            const description = this.state.addNewDescription

            axios
            .post(`${process.env.REACT_APP_API}/api/add-category`,{name,description,url})
            .then(category =>{

                    const addedCategories=this.state.categories
                    addedCategories.push(category.data)
                    this.setState({
                        categories:addedCategories                    
                    })
                    this.sortByName()
            })
        }        
    }
    addCategoryButton = () =>{
        this.setState({
            addButton:!this.state.addButton
        })
    }
    editCategoryButton =() =>{
        this.setState({
            editButton:!this.state.editButton
        })
    }
    editCategory = (event,category_id) =>{
        event.preventDefault()
        if(this.state.editFile){ 
            
            const uploadData = new FormData()
            uploadData.append("url", this.state.editFile);
            axios.post(`${process.env.REACT_APP_API}/api/upload-category`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                const editName = this.state.editCategoryName
                const editDescription = this.state.editCategoryDescription
                axios
                .put(`${process.env.REACT_APP_API}/api/edit-category/${category_id}`,{editName,editDescription,url})
                .then(category =>{

                        const edittedCategories=this.state.categories
                        const index= edittedCategories.findIndex(category =>{
                            return category._id===category_id
                        })
                        edittedCategories[index]=category.data
                        this.setState({
                            categories:edittedCategories                    
                        })
                        this.sortByName()
                })
            })      
        }else{
            const url = this.state.url
            const editName = this.state.editCategoryName
            const editDescription = this.state.editCategoryDescription
            axios
            .put(`${process.env.REACT_APP_API}/api/edit-category/${category_id}`,{editName,editDescription,url})
            .then(category =>{
                    const edittedCategories=this.state.categories

                    const index= edittedCategories.findIndex(category =>{
                        return category._id===category_id
                    })
                    edittedCategories[index]=category.data
                    this.setState({
                        categories:edittedCategories                    
                    })
                    this.sortByName()
            })
        }
    }
    deleteCategory = (category_id) =>{
        

        axios.delete(`${process.env.REACT_APP_API}/api/delete-category/${category_id}`)
        .then(categoryRetrieved =>{
    
            let copyCategory=this.state.categories
            const index = copyCategory.findIndex((category)=>{
                return category._id===category_id
            })
            copyCategory.splice(index,1)

            this.setState({
                categories:copyCategory
            })
            this.sortByName()

        })
    }

    render(){

        return(     
            <div className="content">
                <ClipLoader css={override} size={450} color={"#123abc"} loading={this.state.loading} />
                {this.state.user && !this.state.addButton && 
                <Button variant="crud" onClick={this.addCategoryButton}>Adicionar nova categoria</Button>
                }
                {this.state.user && this.state.addButton &&
                <form>
                    <label>Nome da categoria</label>
                    <input type="text" placeholder="Bolos tradicionais" name="addCategoryName" value ={this.state.addCategoryName} onChange={this.handleChange}></input>
                    <label>Descrição</label>
                    <input type="text" placeholder="" name="addNewDescription" value ={this.state.addNewDescription} onChange={this.handleChange}></input>
                    <label>Seleccionar Imagem</label>
                    <input id="upload-file" name="file" type="file" onChange={this.handleFileChange} /> 
                    <Button variant="crud" onClick={this.addNewCategory}>Submeter nova categoria</Button>
                    <Button variant="crud" onClick={this.addCategoryButton}>Voltar atrás</Button>
                </form>
                }
                <Container className="container-fluid mt-4">
                    <CardDeck className="center" >
                    {this.state.categories &&
                    this.state.categories.map((category)=>{
                        return(
                            <Col key={category._id} xs={8} md={4} lg={4} xl={4}>
                                <Card className="meal card" >
                                    <Link to ={`category/${category._id}`}>
                                    <Card.Img variant="top" src={category.url} />
                                    </Link>
                                    <Card.Body>
                                    <h3>{category.name}</h3>
                                    {this.state.user &&
                                    <Button variant="crud" onClick={()=>{this.deleteCategory(category._id)}}>Eliminar categoria</Button>}
                                    {this.state.user && !this.state.editButton && 
                                    <Button variant="crud" onClick={this.editCategoryButton}>Editar categoria</Button>}
                                    {this.state.user && this.state.editButton &&
                                    <form>
                                    <label>Nome da categoria</label>
                                    <input type="text" placeholder="Bolos tradicionais" name="editCategoryName" value ={this.state.editCategoryName} onChange={this.handleChange}></input>
                                    <label>Descrição</label>
                                    <input type="text" placeholder="" name="editCategoryDescription" value ={this.state.editCategoryDescription} onChange={this.handleChange}></input>
                                    <label>Seleccionar Imagem</label>
                                    <input id="upload-file" name="editFile" type="file" onChange={this.handleFileChange} /> 
                                    <Button variant="crud" onClick={(event)=>{this.editCategory(event,category._id)}}>Submeter edição categoria</Button>
                                    <Button variant="crud" onClick={this.editCategoryButton}>Voltar atrás</Button>
                                </form>}
                                    </Card.Body>
                                </Card>
                            </Col>)
                            })}                  
                    </CardDeck>
                </Container>
            </div>
        )
    }
}

export default ListOfCategories