import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <MDBFooter className="font-small pt-4">
      <MDBContainer fluid className="text-center">
        <MDBRow>
          <MDBCol md="4">
            <h2 className="title">Telefone</h2>
            <h3>
              214263541
            </h3>
          </MDBCol>
          <MDBCol md="4">
            <h2 className="title">Horário</h2>
            <h3>
              06:00h - 20:00h
            </h3>
          </MDBCol>
          <MDBCol md="4">
            <h2 className="title">Hiperligações</h2>
              <h3><a className="footer-link" href="https://www.livroreclamacoes.pt">Livro de reclamações</a></h3>
              <h3><NavLink className="footer-link" to="/politica-privacidade">Política de Privacidade</NavLink></h3>
              <h3><NavLink className="footer-link" to="/termos-e-condicoes">Termos e Condições</NavLink></h3>

          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <h4>&copy; {new Date().getFullYear()} Desenvolvido por : <a className="footer-link" href="https://github.com/andreElCoder"> andreElCoder </a></h4>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default Footer;