import React, { Component } from "react"
import {Col,Card, Button} from "react-bootstrap"
import axios from "axios"
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0,81,115);
`;

class Product extends Component{

    state={
        name:"",
        description:"",
        category:"",
        url:"",
        editProduct:false,
        editName:"",
        editDescription:"",
        file:"",
        loading:true
    }

    componentDidMount(){
        axios.get(`${process.env.REACT_APP_API}/api/product-detail/${this.props.id}`)
        .then(response =>{
            this.setState({
                name:response.data.name,
                editName:response.data.name,
                url:response.data.url,
                description:response.data.description,
                editDescription:response.data.description,
                user:this.props.user,
                loading:false
            })
            this.liftUpProductNameAndId()
        })
    }
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }
    handleFileChange = (event) => {
        const {name} = event.target;
        this.setState({ [name]: event.target.files[0]});
    }
    liftUpProductNameAndId =()=>{
        
        const productNameAndId={id:this.props.id,name:this.state.name}
        this.props.liftUpName(productNameAndId)
    }
    editProduct = ()=>{
        this.setState({
            editProduct:!this.state.editProduct
        })
    }

    submitEditProduct = () =>{
        
        const {editName,editDescription,category} = this.state
        
        if(this.state.file){
            const uploadData = new FormData()
            uploadData.append("url", this.state.file);

            axios.post(`${process.env.REACT_APP_API}/api/upload-product`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                axios.put(`${process.env.REACT_APP_API}/api/edit-product/${this.props.id}`,{editName,editDescription,category,url})
                .then(response =>{
                    this.setState({
                        name:response.data.name,
                        description:response.data.description,
                        editProduct:!this.state.editProduct,
                        url:url
                    })
                })
            })
        }
        else{
            const url = this.state.url
            axios.put(`${process.env.REACT_APP_API}/api/edit-product/${this.props.id}`,{editName,editDescription,category,url})
            .then(response =>{
                this.setState({
                    name:response.data.name,
                    description:response.data.description,
                    editProduct:!this.state.editProduct,
                    url:url
                })
            })
        }

    }

    deleteProductAndLiftUp = () =>{
        this.props.deleteProduct(this.props.id)
    }
    render(){
        return(
            <Col xs={8} md={4} lg={4} xl={4}>
                <ClipLoader css={override} size={450} color={"#123abc"} loading={this.state.loading} />
                <Card className="category">
                    <Card.Img variant="top" src={this.state.url} />
                    <Card.Body>
                    <Card.Title>
                    <h3>{this.state.name}</h3>
                    </Card.Title>
                    <Card.Text>
                    </Card.Text>
                    {this.state.user &&
                    this.state.editProduct?
                    <div> 
                    <Button variant="crud" onClick={this.submitEditProduct}>Submeter edição Producto</Button>
                    <Button variant="crud" onClick={this.editProduct}>Voltar atrás</Button>
                        <form>
                            <label>Nome</label>
                            <input type="text" placeholder={this.state.editName} name="editName" value ={this.state.editName} onChange={this.handleChange}></input>
                            <label>Descrição</label>
                            <input type="text" placeholder={this.state.editDescription} name="editDescription" value ={this.state.editDescription} onChange={this.handleChange}></input>
                            <label>Seleccionar Imagem</label>
                            <input id="upload-file"  name="file" type="file" onChange={this.handleFileChange} /> 
                                <Button onClick={this.submitEditProduct}>Submeter edição</Button>
                        </form>
                    </div>
                    :this.state.user &&
                    <div>
                        <Button variant="crud" onClick={this.editProduct}>Editar Producto</Button>
                        <Button variant="crud" onClick={this.deleteProductAndLiftUp}>Eliminar Producto</Button>
                    </div>
                    }
                    </Card.Body>                     
                </Card>
            </Col>            
            
        )
    }
}
export default Product