import React, { Component} from 'react';
import { GoogleMap, LoadScript , Marker, InfoWindow} from '@react-google-maps/api';
import mapStyles from "../../mapStyles"

require('dotenv').config()

const containerStyle = {
  width: '768px',
  height: '500px'
};

const options = {
  styles: mapStyles,
  disableDefaultUI: false,
  zoomControl : true
}
const libraries=["places"]

/*                icon={{
                  url: "/coffee.svg",
                  scaledSize: new window.google.maps.Size(30,30),
                  origin: new window.google.maps.Point(0,0),
                  anchor: new window.google.maps.Point(15,15)
                }}
<div>Icons used as Markers, made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
*/

class Map extends Component{

  state={
  markers  : [{lat: 38.7489705,lng: -9.2992756}],
  selected : {lat: 38.7489705,lng: -9.2992756},
  center :   {lat: 38.7489705,lng: -9.2992756},
  address : "Av. do Brasil nº 153 a, 2735-675 Agualva-Cacém",
  }

  insertMarker = (event) =>{
    if(this.state.markers && this.state.editable){
      this.setState({
        markers :  [...this.state.markers,{
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        }]
      })
    } else{
      if(this.state.editable){
        this.setState({
          markers: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          }
        })
      }
    }
    if(this.state.editable) this.props.liftUpMarkers(this.state.markers)
  }

  selectMarker = (marker) =>{
      if(!this.state.editable){
      this.setState({
        selected: marker
      })
    } else{
        const markersCopy = this.state.markers
        let index = markersCopy
                    .findIndex(markerElementArray => 
                      {return markerElementArray.lat ===marker.lat && markerElementArray.lng===marker.lng})

        markersCopy.splice(index,1)
        this.setState({
          markers: markersCopy
        })
    }
  }
  unSelectMarker = () =>{
    
    this.setState({
      selected: null
    })
  
}
addMarkerFromSearch = (marker) =>{
  if(this.state.markers){
    this.setState({
      markers :  [...this.state.markers,marker],
      center : marker,
      name : this.props.coffeeName
    })
    } 
    else{
      this.setState({
        markers: marker,
        name : this.props.coffeeName
      })
    }
}

render(){

  return (
    <div className="content">
      <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
      libraries={libraries}
      >
      <GoogleMap
        id="mapa"
        mapContainerStyle={containerStyle}
        center={this.state.center}
        zoom={16}
        options={options}
        onClick={this.insertMarker}
      >
        
        { /* Child components, such as markers, info windows, etc. */ }
  {this.state.markers ? this.state.markers.map((marker,i) => {

          return(
             <Marker
              key={i}
              position={{lat:marker.lat, lng: marker.lng}}
              onClick={() => this.selectMarker(marker)}
              
             />
             )
        })
      :null}
        {this.state.selected ?
        <InfoWindow
          
          position={this.state.selected}
          onCloseClick={this.unSelectMarker}
        >
        <div>
      <h1 className="infobox">Pastelaria Andremar São Marcos</h1>
      <h1 className="infobox">{this.state.address}</h1>
      <a href="https://maps.google.com/maps?ll=38.748955,-9.299223&z=20&t=m&hl=pt-PT&gl=US&mapclient=apiv3&cid=6909635713460510956">Ver no Google Maps</a>
      </div>
  </InfoWindow>:null}
        <></>
      </GoogleMap>
      
      </LoadScript>
    </div>
  )
}
}
export default Map