import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import AuthService from '../components/auth/auth-service';
import { NavLink } from 'react-router-dom';

class MyNavBar extends Component{
    state={
        user:this.props.loggedInUser
    }
    service = new AuthService();

    render(){
    return(
        <Navbar id ="Navbar"  expand="md" variant="dark">
        <Navbar.Brand  id="logo-id">
        <NavLink id="logo-link"to="/"><img 
            src="/logo-true.jpg"
            className="logo"
            alt="Andremar logo"
            />SÃO MARCOS</NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"id="collapsed-links">
            <NavLink className="navlinks" activeClassName="selected" to="/almocos">Almoços</NavLink>
            <NavLink className="navlinks" activeClassName="selected" to="/pastelaria">Pastelaria</NavLink>
            <NavLink className="navlinks" activeClassName="selected" to="/contactos">Contactos</NavLink>
            <NavLink className="navlinks" activeClassName="selected" to="/sobre">Sobre</NavLink>
            {this.props.loggedInUser &&<Nav.Link  onClick={this.service.logout} href="/">Logout</Nav.Link>}
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    )}
}
export default MyNavBar