import React, { Component } from "react"
import axios from "axios"
import {Button,Container} from "react-bootstrap"

class ListOfMeals extends Component{

    componentDidMount(){
        axios
        .get(`${process.env.REACT_APP_API}/api/meals`)
        .then(response =>{
            this.setState({listOfMeals:response.data})
        })
    }
    state={
        listOfMeals:[],
        day:this.props.day,
        storeId:this.props.storeId
    }

    render(){
        
        return(
            <div>
                {this.state.listOfMeals && this.state.listOfMeals.map((meal)=>{
                    return <Button variant="crud"  onClick={(event)=>this.props.associateMeal(event,meal,this.props.day,this.props.storeId)}key={meal._id}>{meal.name}</Button>
                    })
                }
            </div>
        )
    }
}
export default ListOfMeals