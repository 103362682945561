import React from "react"
import { Component } from "react"
import {Card, CardGroup} from "react-bootstrap"

class About extends Component{



    render(){
        return(
            <div className="content">
                <CardGroup>
                    <Card className="about-card">
                        <Card.Img className="about-image" variant="top" src="https://res.cloudinary.com/dxqsjpa34/image/upload/v1609845782/myFolder/IMG_9390.jpg.jpg" />
                    </Card>
                    <Card>
                        <Card.Body className="about-body">
                        <h3 className="about-text">
Foi no Verão de 1996 que nasceu a Pastelaria Andremar. Localizada em São Marcos – Sintra, com o intuito de proporcionar à população uma vasta variedade de pão e pastelaria.
Na sequência do desenvolvimento do parque tecnológico Taguspark, cada vez mais pessoas pretendiam uma opção para almoçar, foi desde então que passámos a disponibilizar refeições caseiras e económicas.
Pode vir almoçar aqui! Disponibilizamos sempre três pratos do dia diferentes, para além das opções convencionais, como o belo bitoque e a alheira.
Privilegiamos o ambiente familiar, onde possa estar à vontade, a beber o seu café e a ler o seu jornal de eleição. 

                        </h3>
                    </Card.Body>
                    </Card>
                </CardGroup>
            </div>
)}
}
export default About 