import React, { Component } from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import AuthService from './components/auth/auth-service';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import InitialPage from './components/InitialPage';
import MyNavBar from './components/MyNavBar'
import Ementa from './components/meal/Ementa'
import Contacts from './components/contacts/Contacts'
import Footer from './components/Footer'
import ListOfCategories from './components/categories/ListOfCategories'
import Category from './components/categories/Category'
import About from './components/About'
import Privacy from './components/Privacy'
import TermsAndConditions from './components/TermsAndConditions'

class App extends Component {

  state = {
    loggedInUser: null 
  }
  service = new AuthService();

  setCurrentUser = (userObj) => {
    this.setState({
      loggedInUser: userObj
    })
  }


  // 1. save the user into the browser localstorage
  // OR
  // 2. check if the user is still loggedin by calling the backend
  fetchUser = () => {
    if(this.state.loggedInUser === null) {
      this.service.loggedin() 
        .then(response => {
          if (response._id) {
            this.setState({
              loggedInUser: response
            })
          }
        })
    }
  }

  render() {
    this.fetchUser();
    return (
      <div className="App">
        <MyNavBar loggedInUser={this.state.loggedInUser}/>
        <Switch>
          <Route exact path='/' component={InitialPage}/>
          {//<Route exact path='/signup' render={(props) => <Signup setCurrentUser={this.setCurrentUser} {...props} /> } />
          }
          <Route exact path='/login' render={(props) => <Login setCurrentUser={this.setCurrentUser} {...props} /> } />
          <Route exact path='/almocos' render={(props) => <Ementa loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/category/:id' render={(props) => <Category loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/contactos' render={(props) => <Contacts loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/pastelaria' render={(props) => <ListOfCategories loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/termos-e-condicoes' render={(props) => <TermsAndConditions loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/politica-privacidade' render={(props) => <Privacy loggedInUser={this.state.loggedInUser} {...props} /> } />
          <Route exact path='/sobre' component ={About}/>
        </Switch>
        <Footer/>
      </div>
    );
  }
}

export default App;