import React, { Component } from "react"
import axios from "axios"
import Map from "./Map"

class Contacts extends Component{

    state={
        stores : [],
        user : false
    }

    componentDidMount(){

        axios
        .get(`${process.env.REACT_APP_API}/api/stores`)
        .then(stores =>{
            this.setState({
                stores:stores.data,
                user:this.props.loggedInUser
            })
        })
    }


    render(){
        return(
        <div className="contacts">
            <Map></Map>
            {this.state.stores.map(store =>{
                return(
                <div key={store._id} className="contact-info">
                
                    <h3>{store.name}</h3>
                    <h4>Contacto: {store.contact}</h4>
                    <h4>Email: {store.email}</h4>  
                    
                </div>)
            }
            )}
        </div>
    )}      

}
export default Contacts