import React, { Component } from "react"
import axios from "axios"
import Meal from './Meal'
import {CardDeck, Button, Container} from "react-bootstrap"
import ListOfMeals from "./ListOfMeals"
import {WhatsappShareButton, WhatsappIcon} from "react-share"
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0,81,115);
`;

class Ementa extends Component{

    componentDidMount(){

        axios
        .get(`${process.env.REACT_APP_API}/api/stores`)
        .then(stores =>{
            this.setState({
                stores:stores.data,
                user:this.props.loggedInUser,
                dayOfTheWeekPlusOne:stores.data[0].dayOfTheWeekPlusOne,
                loading:false
            })
            if(stores.data[0]){
                this.setState({
                    date:new Date(stores.data[0].lastModification)
                })
            }
        })
    }
    state ={
        stores:[],
        addTodayMealButton:false,
        addTodayMeal:"",
        addTodayDescription:"",
        showListToday:false,
        addTomorrowButton:false,
        addTomorrowMeal:"",
        addTomorrowDescription:"",
        showListTomorrow:false,
        addAfterTomorrowButton:false,
        addAfterTomorrowMeal:"",
        addAfterTomorrowDescription:"",
        showListAfterTomorrow:false,
        addRegularButton:false,
        addRegularMeal:"",
        addRegularDescription:"",
        showListRegular:false,
        fileToday:"",
        fileTomorrow:"",
        fileAfterTomorrow:"",
        fileRegular:"",
        addNewStoreButton:false,
        addNewStoreName:"",
        addNewStoreEmail:"",
        addNewStoreContact:214263541,
        user:false,
        date:"",
        dayOfTheWeekPlusOne:"",
        loading:true
    }
    
    addNewStore = () =>{
        const name = this.state.addNewStoreName
        const email= this.state.addNewStoreEmail
        const contact = this.state.addNewStoreContact
        
        axios.post(`${process.env.REACT_APP_API}/api/add-store`,{name,email,contact})
        .then((addedStore) =>{
            const copyStores=this.state.stores
            copyStores.push(addedStore.data)
            this.setState({stores:copyStores})
        })
    }
    addTodayMealButton = () =>{
        this.setState({
            addTodayMealButton:!this.state.addTodayMealButton
        })
    }
    addTomorrowMealButton = () =>{
        this.setState({
            addTomorrowMealButton:!this.state.addTomorrowMealButton
        })
    }
    addAfterTomorrowMealButton = () =>{
        this.setState({
            addAfterTomorrowMealButton:!this.state.addAfterTomorrowMealButton
        })
    }
    addRegularMealButton = () =>{
        this.setState({
            addRegularMealButton:!this.state.addRegularMealButton
        })
    }
    addMeal = (event,name,description,todayOrTomorrow,storeId) =>{
        event.preventDefault()
        const uploadData = new FormData()
        if(this.state.fileToday || this.state.fileTomorrow || this.state.fileRegular){
            if(todayOrTomorrow==="today"){
                uploadData.append("url", this.state.fileToday);
            }
            else if(todayOrTomorrow==="tomorrow"){
                uploadData.append("url", this.state.fileTomorrow);
            }
            else if(todayOrTomorrow==="afterTomorrow"){
                uploadData.append("url", this.state.fileAfterTomorrow);
            }
            else{
                uploadData.append("url", this.state.fileRegular);
            }

            axios.post(`${process.env.REACT_APP_API}/api/upload-meal`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                const store = storeId
                axios
                .post(`${process.env.REACT_APP_API}/api/add-meal`,{name,store,todayOrTomorrow,url,description})
                .then(meals =>{

                        //newToday.push()
                        const copyStore=this.state.stores
                        const index = copyStore.findIndex((store)=>{
                            return store._id===storeId
                        })
                        copyStore[index].today=meals.data.today
                        copyStore[index].tomorrow=meals.data.tomorrow
                        copyStore[index].afterTomorrow=meals.data.afterTomorrow
                        copyStore[index].regular=meals.data.regular
                        this.setState({
                            stores:copyStore
                        })
                })
            })

            }
            else{
                const url = ""
                const store = storeId
                axios
                .post(`${process.env.REACT_APP_API}/api/add-meal`,{name,store,todayOrTomorrow,url,description})
                .then(meals =>{             
                        //newToday.push()

                        const copyStore=this.state.stores

                        const index = copyStore.findIndex((store)=>{
                            return store._id===storeId
                        })
                        copyStore[index].today=meals.data.today
                        copyStore[index].tomorrow=meals.data.tomorrow
                        copyStore[index].afterTomorrow=meals.data.afterTomorrow
                        copyStore[index].regular=meals.data.regular
                        this.setState({
                            stores:copyStore
                        })

                })
            }
    }
    associateMeal = (event,meal,todayOrTomorrow,storeId) =>{
        event.preventDefault()
        const meal_id=meal._id
        const store_id=storeId
        axios
        .post(`${process.env.REACT_APP_API}/api/associate-meal-to-store`,{meal_id,store_id,todayOrTomorrow})
        .then(meals =>{
                //newToday.push()
                const copyStore=this.state.stores
                const index = copyStore.findIndex((store)=>{
                    return store._id===storeId
                })
                copyStore[index].today=meals.data.today.sort()
                copyStore[index].tomorrow=meals.data.tomorrow.sort()
                copyStore[index].afterTomorrow=meals.data.afterTomorrow.sort()
                copyStore[index].regular=meals.data.regular.sort()
                this.setState({
                    stores:copyStore
                })

        })
    }
    
    showListToday = () =>{
        this.setState({showListToday:!this.state.showListToday})
    }
    showListTomorrow = () =>{
        this.setState({showListTomorrow:!this.state.showListTomorrow})
    }
    showListAfterTomorrow = () =>{
        this.setState({showListAfterTomorrow:!this.state.showListAfterTomorrow})
    }
    showListRegular = () =>{
        this.setState({showListRegular:!this.state.showListRegular})
    }
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }
    handleFileChange = (event) => {
        const {name} = event.target;
        this.setState({ [name]: event.target.files[0]});
    }
    deleteStore =(event,id) =>{
        event.preventDefault()
        axios.delete(`${process.env.REACT_APP_API}/api/delete-store/${id}`)
        .then(response =>{
            const copyStores = this.state.stores.filter((store)=>{

                return store._id!==id
            })
            
            this.setState({
                stores:copyStores
            })
        })
    }
    changeAddNewStoreButton = () =>{
        this.setState({
        addNewStoreButton:!this.state.addNewStoreButton
        })
    }
    detachAndLiftUp = (day,meal_id,store_id) =>{
        
        const todayOrTomorrow=day

        axios.put(`${process.env.REACT_APP_API}/api/detach-meal-to-store`,{meal_id,store_id,todayOrTomorrow})
        .then(storeRetrieved =>{

            const copyStore=this.state.stores
            const index = copyStore.findIndex((store)=>{

                return store._id===store_id
            })
            copyStore[index].today=storeRetrieved.data.today
            copyStore[index].tomorrow=storeRetrieved.data.tomorrow
            copyStore[index].afterTomorrow=storeRetrieved.data.afterTomorrow
            copyStore[index].regular=storeRetrieved.data.regular
            this.setState({
                stores:copyStore
            })
        })
    }
    deleteAndLiftUp = (day,meal_id,store_id) =>{

        axios.delete(`${process.env.REACT_APP_API}/api/delete-meal/${meal_id}`)
        .then(storeRetrieved =>{

            const copyStore=this.state.stores
            const index = copyStore.findIndex((store)=>{
                return store._id===store_id
            })

            copyStore[index].today=storeRetrieved.data.today
            copyStore[index].tomorrow=storeRetrieved.data.tomorrow
            copyStore[index].afterTomorrow=storeRetrieved.data.afterTomorrow
            copyStore[index].regular=storeRetrieved.data.regular
            this.setState({
                stores:copyStore
            })
        })
    }

    render(){
        return(
        <div className="content">
             <ClipLoader css={override} size={450} color={"#123abc"} loading={this.state.loading} />
            {this.state.user && this.state.addNewStoreButton ? 
                <div>
                    <form>
                        <label>Nome da loj</label>
                        <input type="text" placeholder="São Marcos" name="addNewStoreName" value ={this.state.addNewStoreName} onChange={this.handleChange}></input> 
                        <label>Email da loja</label>
                        <input type="email" placeholder="pastelaria_andremar@sapo.pt" name="addNewStoreEmail" value ={this.state.addNewStoreEmail} onChange={this.handleChange}></input> 
                        <label>Contacto da loja</label>
                        <input type="number" placeholder="214263541" name="addNewStoreContact" value ={this.state.addNewStoreContact} onChange={this.handleChange}></input> 
                        <Button variant="crud" onClick={this.addNewStore}>Submeter nova loja</Button>
                        <Button variant="crud" onClick={this.changeAddNewStoreButton}>Voltar atrás</Button>
                    </form>
                </div>:
                <div>
                    {this.state.user && <Button variant="crud"onClick={this.changeAddNewStoreButton}>Adicionar nova loja</Button>}
                </div>
            }
            {this.state.stores && this.state.stores.map((store)=>{return(
                <div className="store"key={store._id}>
                     {this.state.user &&<Button variant="crud" onClick={(event)=>{this.deleteStore(event,store._id)}}>Eliminar esta loja</Button>}
                    <div>
                        <h1>{store.name}</h1>
                        <div className="date-share">  
                             
                             <WhatsappShareButton title="Ementa" url="https://andremar.pt/almocos"><WhatsappIcon size={32} borderRadius ="50"></WhatsappIcon></WhatsappShareButton>
                        </div>
                      
                        {this.state.user && 
                        <div className="row-buttons">
                            {/*MEALS TODAY*/} 
                            {this.state.addTodayMealButton ?
                                <form>
                                    <label>Nome do prato</label>
                                    <input type="text" placeholder="Arroz de Pato" name="addTodayMeal" value ={this.state.addTodayMeal} onChange={this.handleChange}></input>
                                    <label>Descrição</label>
                                    <input type="text" name= "addTodayDescription" value={this.state.addTodayDescription} onChange={this.handleChange}></input>
                                    <label>Seleccionar Imagem</label>
                                    <input id="upload-file" name="fileToday" type="file" onChange={this.handleFileChange} /> 
                                    <Button variant="crud" onClick={(event)=>{this.addMeal(event,this.state.addTodayMeal,this.state.addTodayDescription,"today",store._id)}}>Submeter refeição nova para hoje</Button>
                                    <Button variant="crud" onClick={this.addTodayMealButton}>Voltar atrás</Button>
                                </form>
                            :
                            <div>
                                <Button variant="crud" onClick={this.addTodayMealButton}>Adicionar refeição para hoje</Button> 
                            </div>}
                        </div>}
                    </div>
                    {
                    this.state.user &&  
                    <div>
                        {this.state.showListToday? <Button variant="crud" onClick={this.showListToday}>Voltar atrás</Button>:<Button variant="crud" onClick={this.showListToday}>Associar refeição de hoje</Button>}
                            {this.state.showListToday &&<ListOfMeals day="today" associateMeal ={this.associateMeal} storeId={store._id}/>}
                    </div>}
                    <div className="border-meal"><h2>Hoje</h2>
                        {<Container className="container-fluid mt-4"><CardDeck className="center"> {store.today.map((mealId) =>{
                            return <Meal user={this.state.user} key={mealId} meal={mealId} day="today" detach={this.detachAndLiftUp} delete={this.deleteAndLiftUp} storeId={store._id}/>
                        })}</CardDeck></Container> }            
                    </div>
                    <div>

                        {this.state.user && 
                        <div className="row-buttons">
                            {/*MEALS Tomorrow*/} 
                            {this.state.addTomorrowMealButton ?
                                <form>
                                    <label>Nome do prato</label>
                                    <input type="text" placeholder="Arroz de Pato" name="addTomorrowMeal" value ={this.state.addTomorrowMeal} onChange={this.handleChange}></input>
                                    <label>Descrição</label>
                                    <input type="text" name="addTomorrowDescription" value={this.state.addTomorrowDescription} onChange={this.handleChange}></input>
                                    <label>Seleccionar Imagem</label>
                                    <input id="upload-file" name="fileTomorrow" type="file" onChange={this.handleFileChange} /> 
                                    <Button variant="crud" onClick={(event)=>{this.addMeal(event,this.state.addTomorrowMeal,this.state.addTomorrowDescription,"tomorrow",store._id)}}>Submeter refeição nova para amanhã</Button>
                                    <Button variant="crud" onClick={this.addTomorrowMealButton}>Voltar atrás</Button>
                                </form>
                            :
                            <div>
                                <Button variant="crud" onClick={this.addTomorrowMealButton}>Adicionar refeição para amanhã</Button> 
                            </div>}
                        </div>}
                    </div>
                    {
                    this.state.user &&  
                    <div>
                        {this.state.showListTomorrow? <Button variant="crud" onClick={this.showListTomorrow}>Voltar atrás</Button>:<Button variant="crud" onClick={this.showListTomorrow}>Associar refeição de amanhã</Button>}
                            {this.state.showListTomorrow &&<ListOfMeals day="tomorrow" associateMeal ={this.associateMeal} storeId={store._id}/>}
                    </div>}
                    <div className="border-meal"><h2>{this.state.dayOfTheWeekPlusOne}</h2>
                        {<Container className="container-fluid mt-4"><CardDeck  className="center"> {store.tomorrow.map((mealId) =>{
                            return <Meal user={this.state.user} key={mealId} meal={mealId} day="tomorrow" detach={this.detachAndLiftUp} delete={this.deleteAndLiftUp} storeId={store._id}/>
                        })}</CardDeck></Container>}            
                    </div>
                    <div>

                        {this.state.user && 
                        <div className="row-buttons">
                            {/*MEALS AfterTomorrow*/} 
                            {this.state.addAfterTomorrowMealButton ?
                                <form>
                                    <label>Nome do prato</label>
                                    <input type="text" placeholder="Arroz de Pato" name="addAfterTomorrowMeal" value ={this.state.addAfterTomorrowMeal} onChange={this.handleChange}></input>
                                    <label>Descrição</label>
                                    <input type="text" name="addAfterTomorrowDescription" value={this.state.addAfterTomorrowDescription} onChange={this.handleChange}></input>
                                    <label>Seleccionar Imagem</label>
                                    <input id="upload-file" name="fileAfterTomorrow" type="file" onChange={this.handleFileChange} /> 
                                    <Button variant="crud" onClick={(event)=>{this.addMeal(event,this.state.addAfterTomorrowMeal,this.state.addAfterTomorrowDescription,"afterTomorrow",store._id)}}>Submeter refeição nova para depois de amanhã</Button>
                                    <Button variant="crud" onClick={this.addAfterTomorrowMealButton}>Voltar atrás</Button>
                                </form>
                            :
                            <div>
                                <Button variant="crud" onClick={this.addAfterTomorrowMealButton}>Adicionar refeição para depois amanhã</Button> 
                            </div>}
                        </div>}
                    </div>
                    {
                    this.state.user &&  
                    <div>
                        {this.state.showListAfterTomorrow? <Button variant="crud" onClick={this.showListAfterTomorrow}>Voltar atrás</Button>:<Button variant="crud" onClick={this.showListAfterTomorrow}>Associar refeição para depois de amanhã</Button>}
                            {this.state.showListAfterTomorrow &&<ListOfMeals day="afterTomorrow" associateMeal ={this.associateMeal} storeId={store._id}/>}
                    </div>}
                    {this.state.user && <div className="border-meal"><h2>Depois de Amanhã</h2>
                        {<Container className="container-fluid mt-4"><CardDeck  className="center"> { store.afterTomorrow.map((mealId) =>{
                            return <Meal user={this.state.user} key={mealId} meal={mealId} day="afterTomorrow" detach={this.detachAndLiftUp} delete={this.deleteAndLiftUp} storeId={store._id}/>
                        })}</CardDeck></Container>} 
                    </div>}
                    <div>{/*REGULAR*/}
                    {this.state.user && 
                        <div className="row-buttons">
                            {/*MEALS Regular*/} 
                            {this.state.addRegularMealButton ?
                                <form>
                                    <label>Nome do prato</label>
                                    <input type="text" placeholder="Arroz de Pato" name="addRegularMeal" value ={this.state.addRegularMeal} onChange={this.handleChange}></input>
                                    <label>Descrição</label>
                                    <input type="text" name="addRegularDescription" value={this.state.addRegularDescription} onChange={this.handleChange}></input>
                                    <label>Seleccionar Imagem</label>
                                    <input id="upload-file" name="fileRegular" type="file" onChange={this.handleFileChange} /> 
                                    <Button variant="crud" onClick={(event)=>{    this.addMeal(event,this.state.addRegularMeal,this.state.addRegularDescription,"regular",store._id)}}>Submeter refeição nova para Outros Pratos</Button>
                                    <Button variant="crud" onClick={this.addRegularMealButton}>Voltar atrás</Button>
                                </form>
                            :
                            <div>
                                <Button variant="crud" onClick={this.addRegularMealButton}>Adicionar refeição para Outros Pratos</Button> 
                            </div>}
                        </div>}
                    </div>
                    {
                    this.state.user &&  
                    <div>
                        {this.state.showListRegular? <Button variant="crud" onClick={this.showListRegular}>Voltar atrás</Button>:<Button variant="crud" onClick={this.showListRegular}>Associar refeição de Outros Pratos</Button>}
                            {this.state.showListRegular &&<ListOfMeals day="regular" associateMeal ={this.associateMeal} storeId={store._id}/>}
                    </div>}
                    <div className="border-meal"><h2>Outros Pratos</h2>
                        {<Container className="container-fluid mt-4"><CardDeck  className="center"> {store.regular.map((mealId) =>{
                            return <Meal user={this.state.user} key={mealId} meal={mealId} day="regular" detach={this.detachAndLiftUp} delete={this.deleteAndLiftUp} storeId={store._id}/>
                        })}</CardDeck></Container>}            
                    </div>
                </div>
            )})}       
        {
        this.state.date &&
        <div>
            <h5>Actualizado em: {this.state.date.toLocaleDateString()+" "+this.state.date.toLocaleTimeString()}
            </h5> 
        </div>
        }
    </div>
            )
    }
}

export default Ementa