import React, {Component} from "react"
import {Card , Button,Col} from "react-bootstrap"
import axios from "axios"
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(0,81,115);
`;

class Meal extends Component{

    componentDidMount(){
        axios.get(`${process.env.REACT_APP_API}/api/meal-detail/${this.props.meal}`)
        .then(response =>{
            this.setState({
                name:response.data.name,
                description:response.data.description,
                editName:response.data.name,
                editDescription:response.data.description,
                day:this.props.day,
                id:response.data._id,
                url:response.data.url,
                loading:false
            })
        })
    }
    state ={
        name:"name",
        editName:"",
        description:"description",
        editDescription:"",
        day:"day",
        id:"id",
        edit:false,
        url:"",
        file:"",
        user:this.props.user,
        loading:true
    }

    detachMeal = () => {
        this.props.detach(this.state.day,this.state.id,this.props.storeId)
    }
    deleteMeal = () =>{
        this.props.delete(this.state.day,this.state.id,this.props.storeId)
    }
    editMeal = () =>{
        
        this.setState({
            edit:!this.state.edit
        })
    }
    submitEditMeal = () =>{
        const {editName,editDescription} = this.state

        if(this.state.file){
            const uploadData = new FormData()
            uploadData.append("url", this.state.file);

            axios.post(`${process.env.REACT_APP_API}/api/upload-meal`, uploadData)
            .then(responseFromUploadImage =>{
                const url = responseFromUploadImage.data.url
                axios.put(`${process.env.REACT_APP_API}/api/edit-meal/${this.state.id}`,{editName,editDescription,url})
                .then(response =>{

                    this.setState({
                        name:editName,
                        description:editDescription,
                        edit:!this.state.edit,
                        url:url
                    })
                })
            })
        }
        else{
            const url = this.state.url
            axios.put(`${process.env.REACT_APP_API}/api/edit-meal/${this.state.id}`,{editName,editDescription,url})
            .then(response =>{

                this.setState({
                    name:editName,
                    description:editDescription,
                    edit:!this.state.edit,
                    url:url
                })
            })
        }
    }
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }
    handleFileChange = (event) => {
        const {name} = event.target;
        this.setState({ [name]: event.target.files[0]});
    }
    render (){
        return(
            <Col xs={8} md={4} lg={4} xl={4}>
                <Card className="meal">
                <ClipLoader css={override} size={150} color={"#123abc"} loading={this.state.loading} />

                    {/*<Card.Img variant="top-blue" src={this.state.url} />*/}
                    {!this.state.edit ?<Card.Body>
                    <Card.Title>
                        <h2>{this.state.name}</h2>
                    </Card.Title>
                    <h3>{this.state.description}</h3>
                    {this.state.user &&<Button variant="crud" onClick={this.detachMeal} >Desassociar</Button>}
                    {this.state.user &&<Button variant="crud" onClick={this.editMeal}>Editar Prato</Button>}
                    {this.state.user &&<Button variant="crud" onClick={this.deleteMeal}>Eliminar Prato</Button>}
                    </Card.Body>
                    : 
                    <form>
                    <label>Nome</label>
                    <input type="text" placeholder={this.state.editName} name="editName" value ={this.state.editName} onChange={this.handleChange}></input>
                    <label>Descrição</label>
                    <input type="text" placeholder={this.state.editDescription} name="editDescription" value ={this.state.editDescription} onChange={this.handleChange}></input>
                    <label>Seleccionar Imagem</label>
                    <input id="upload-file"  name="file" type="file" onChange={this.handleFileChange} />                    
                    <Button variant="crud" onClick={this.submitEditMeal}>Submeter edição</Button>
                    <Button variant="crud" onClick={this.editMeal}>Cancelar edição</Button>
                    </form>
                    }

                </Card>
            </Col>
        )
    }
}
export default Meal